import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import { createRouter,createWebHistory } from 'vue-router'
import './assets/css/app.scss'
import routes from './routes'
import App from './App.vue'


const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    //判断是否有标题
    if (to.meta.title) {
        document.title = to.meta.title + " - 山东多源网络科技有限公司";
    } else {
        document.title;
    }
    next()
})

createApp(App).use(ElementPlus).use(router).mount('#app')

