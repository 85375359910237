const routes = [
    {
        name: 'app',
        path: '/',
        component: () => import('./view/index/index.vue'),
        meta: {
            title: '多源网络 SRCKER'
        }
    },
    {
        name: 'product',
        path: '/product',
        component: () => import('./view/product/index.vue'),
        meta: {
            title: '技术能力'
        }
    },
    {
        name: 'market',
        path: '/market',
        component: () => import('./view/market/index.vue'),
        meta: {
            title: '营销策划'
        }
    },
    {
        name: 'news',
        path: '/news',
        component: () => import('./view/news/index.vue'),
        meta: {
            title: '新闻动态'
        }
    },
    {
        name: 'contect',
        path: '/contect',
        component: () => import('./view/contect/index.vue'),
        meta: {
            title: '联系我们'
        }
    }
];

export default routes;